import React from "react";
import classnames from "classnames";
import $ from "jquery";
import Slider from "nouislider";

import {
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    Collapse
} from "reactstrap";
import { injectIntl, FormattedMessage } from 'react-intl';
import FormLoadingIndicator from "../../../../views/pages/components/FormLoadingIndicator";
import Workflow from "../workflow";
import ReactSlidingPane from "react-sliding-pane";
import BusinessService from "../../../../proxies/BusinessService";
import ShipperService from "../../../../proxies/ShipperService";
import SearchService from "../../../../proxies/SearchService";
import Step from "../step";
import TruckSummary from "../../business/truckSummary";
import FormGroupInput from "../../../pages/components/form/FormGroupInput";
import Title2 from "../../common/title2";
import Title3 from "../../common/title3";
import Message from "../../common/message";
import Paragraph from "../../common/paragraph";
import Rating from "../../rating/rating";
import { fr } from 'date-fns/locale'
import { DateRangePicker, Calendar } from 'react-date-range';
import DateHelper from "../../../../helpers/DateHelper";
import DateLabel from "../../common/dateLabel";
import DefaultButton from "../../common/defaultButton";
import orderWorkflow from "./orderWorkflow";
import StringHelper from "../../../../helpers/StringHelper";
import DefaultLabel from "../../common/defaultLabel";
import DefaultImage from "../../common/defaultImage";
import Config from "../../../../config";
import WebsiteHelper from "../../../../helpers/WebsiteHelper";
import Resources from "../../../../resources";
import DefaultLink from "../../common/defaultLink";


var moment = require('moment');
require('moment/locale/fr');

class ResultDetailStep extends Step {

    constructor(props) {
        super(props)
        
        this.businessService = new BusinessService();
        this.shipperService = new ShipperService();
        this.searchService = new SearchService();
        this.dates = false;
        this.order_id = null;
        this.order_payment_transaction_date = null;
        this.order_url = null;
        this.automatic_validation = false;
        this.state = {
            transporter: false,
            openedCollapses: [],
            result: null,
            calendar_index: null,
            dates: []
        }
    }

    componentWillMount() {
        this.startLoading()

        super.componentWillMount()

        this.props.workflow.reload();
        // Récupération du résultat (camion, es, prix, disponibilité, ...)
        const resultId = this.props.workflow.state.input;

        // Vérification du type d'utilisateur
        if (this.businessService.isTransporter())
        {
            this.setState({transporter: true})
            this.props.workflow.exit();
            this.props.workflow.showSearchTransporterErrorMessage();
            return false;
        }


        // Récupération des données du camion
        this.shipperService.getSearchResultDetail(null, resultId, (response) => {
            var dates = []
            this.automatic_validation = response.automatic_validation;
            if(this.props.workflow.state.version === 2) {
                // todo ameliorer quand le webService le fera correctement
                response.desired_days = [ this.props.workflow.state.dates ]
                response.half_day = this.props.workflow.state.half_day
            }
            if (response.strict_date)
            {
                this.dates = true;
                
                response.desired_days.forEach(d => {
                    dates.push(DateHelper.toMysqlDateFormat(DateHelper.phpDateToJavascript(d)))
                })                

                this.props.workflow.reload()
            }
            // Proposition alternative un seul choix pour une seul journée
            else if (response.desired_days.length == 1 && response.proposed_days.length == 1)
            {
                dates.push(DateHelper.toMysqlDateFormat(DateHelper.phpDateToJavascript(response.proposed_days[0].start_date)))
                this.dates = true;
            }
            // Proposition alternative un seul choix pour une plusieurs journées
            else if (response.desired_days.length > 1 && response.proposed_days.length == 1)
            {
                /*response.proposed_days[0].dates[0].forEach((date, index) => {
                    dates.push(DateHelper.toMysqlDateFormat(DateHelper.phpDateToJavascript(date)))
                });*/
                
                response.proposed_days[0].dates[0].forEach((date, index) => {
                    dates.push(date)
                });
                
                this.dates = true;
            }

            if (response.order_id != null)
            {
                //console.log(response)
                this.order_id = response.order_id;
                this.order_payment_transaction_date = response.order_payment_transaction_date
                this.order_url = response.order_url
                this.dates = []

                if (response.order_state_code == "ORDER_STATE_VALIDATED")
                {
                    this.props.workflow.goToSpecificStep("ORDER_PAYMENT_CONFIRMATION", response.order_id);
                }
            }
            
            // Récupération des données
            this.setState({
                result: response,
                dates: dates
            })
            this.stopLoading()
            
            this.props.workflow.reload();
        }, (httpResponse) => {
            this.stopLoading()
            
            // Utilisateur non connecté
            if (httpResponse.status == 401)
            {
                this.props.workflow.notConnected();
            }
            else
            {
                this.showGenericError(httpResponse, "ResultDetailStep", "componentWillMount")
            }
        })
    }

    getPaymentButtonLabel()
    {
        return <DateLabel start_date={this.state.result.order_start_date} end_date={this.state.result.order_end_date} />
    }


    isSearchResult()
    {
        return this.state.result.type == "SEARCH_RESULT";
    }

    isCallForBidsResult()
    {
        return this.state.result.type == "CALL_FOR_BIDS_RESULT";
    }

    isReadOnlyResult()
    {
        return this.state.result.type == "READONLY_RESULT";
    }

    allowPaymentMethodStep()
    {
        return this.dates && this.order_id == null && this.automatic_validation == false && this.order_payment_transaction_date == null;
    }

    allowPaymentStep()
    {
        return this.dates && this.order_id == null && this.automatic_validation == true && this.order_payment_transaction_date == null;
    }

    goBackToPaymentMethodStep()
    {
        return this.order_id != null && this.automatic_validation == false && this.order_payment_transaction_date == null;
    }

    goBackToPaymentStep()
    {
        return this.order_id != null && this.automatic_validation == true && this.order_payment_transaction_date == null;
    }


    getOrderId()
    {
        return this.order_id
    }

    

    collapsesToggle = collapse => {
        let openedCollapses = this.state.openedCollapses;
        if (openedCollapses.includes(collapse)) {
            this.setState({
            openedCollapses: []
            });
        } else {
            this.setState({
            openedCollapses: [collapse]
            });
        }
    };


    componentDidMount() {
        super.componentDidMount()
    }

    submit(pid)
    {
        this.startSubmiting()
        // Si la commande existe déjà, on retourne sur l'écran de paiement
        if (this.order_id != null)
        {
            this.props.workflow.goToNextStep(pid)
            return;
        }
        else if (this.isCallForBidsResult())
        {
            this.props.workflow.goToNextStep(pid)
            return;
        }

        var formIsValid = this.htmlFormIsValid();

        if (formIsValid)
        {
            // Création du brouillon de commande
            let dates = this.state.dates
            let half_day = this.state.result.half_day
            if(this.props.workflow.state.version === 2) {
                dates = this.props.workflow.state.dates
                half_day = this.props.workflow.state.half_day
            }

            var datasToPost = {
                result_id: this.props.workflow.state.input,
                dates,
                half_day
            }

            this.shipperService.createOrder(this, datasToPost, (response) => {
                super.submit(pid)

                // Récupération de l'identifiant de la commande
                const orderId = response.id;
                this.props.workflow.goToNextStep(pid)

                this.stopSubmiting()
            }, (httpErrorResponse) => {
                if (httpErrorResponse.status == 401)
                {
                    // Gestion de l'utilisateur non connecté
                    this.props.workflow.notConnected();
                }
                else 
                {
                    this.stopSubmiting();
                    this.stopLoading();
                    httpErrorResponse.json().then((apiResponse) => {
                        this.showErrorFromResponse(apiResponse)
                    })
                }

                this.stopSubmiting()
            })

            // Vérification des disponibilités du camion pour les dates selectionné avant paiement
            /*this.searchService.validateTruckAvailability(this, this.state.result.id, this.state.dates, (response) => {

                // Si ok redirection vers l'étape de paiement
                super.submit(pid)
                
                // Enregistrement des données de la société (adresse de facturation, licence, rattachement au compte)
                this.props.workflow.goToNextStep(pid);

            })*/
        }
    }


    renderProposition()
    {
        return <>
            <Row>
                <Col xl="10">
                    <Row>
                        <Col xl="12">
                        <Title3>
                            <FormattedMessage id="order.result_detail_step_proposition_title" />
                        </Title3>
                        </Col>
                        <Col xl="12">
                            <Title2>{this.state.result.truck.technical_label}</Title2>
                        </Col>
                    </Row>
                </Col>
                <Col xl="2">
                    {this.renderTruckImage()}
                </Col>
            </Row>
        </>
    }

    renderTruckImage()
    {
        return <div className="img-responsive img-border">
            <Row>
            <img alt="" className="img-center  shadow shadow-lg--hover "
                src={Config.bndApi + "/truck/image/" + this.state.result.truck.id} 
                style={{width: "50%", height: "auto"}} 
            />
            </Row>
            {/*<Row className="justify-content-center text-center">
                    <Row>
                       <Col xs="1"><DefaultImage className="t2y-search-result-certified" src={require("../../../../assets/img/icons/certified_transporter.svg").default}></DefaultImage></Col>
                        <Col xs="10">
                            <label className={"t2y-search-result-label"}>
                            <FormattedMessage id="Search.detail_verify_transporteur" />
                            </label>
                        </Col>
                    </Row>
            </Row>*/}
            <Row className="justify-content-center text-center">
                <Rating note={parseFloat(this.state.result.rating) / 2}></Rating>
            </Row>
        </div>
    }

    renderTruckDetail()
    {
        return <>
            <Row style={{height:"80%", overflow:"scroll"}}>
                <Col xs="12">
                    <Row>
                        <Col xs="8">
                            <Row>
                                <Col xs="12">
                                    <Title2>
                                        {this.state.result.truck.technical_label}
                                    </Title2>
                                </Col>
                                {/*<Col xs="12">
                                    <div style={{float:"left"}} className="mr-2">
                                        <DefaultImage className="t2y-search-result-certified" src={require("../../../../assets/img/icons/certified_transporter.svg").default}></DefaultImage>
                                    </div>
                                    <div style={{float:"left"}}>
                                        <label className={"t2y-search-result-label"}><FormattedMessage id="Search.detail_verify_transporteur" /></label>
                                    </div>
                                </Col>*/}
                                <Col xs="12">
                                    <div style={{float:"left"}} className="mr-2">
                                        <label className="t2y-search-result-label">Note qualité :</label>
                                    </div>
                                    <div style={{float:"left"}}>
                                        <Rating note={parseFloat(this.state.result.rating) / 2}></Rating>
                                    </div>
                                </Col>
                            </Row>
                            
                        </Col>
                        <Col xs="4">
                            <TruckSummary properties={false} label={false} image={true} truck={this.state.result.truck}></TruckSummary>
                        </Col>
                    </Row>
                </Col>
                <Col xs="12">
                    <TruckSummary properties={true} label={false} image={false} id={this.state.result.truck.id}></TruckSummary>
            
                    <Row>
                    <Col xs="6">
                            <DefaultLabel>Note éco</DefaultLabel>
                    </Col>
                    <Col xs="6">
                        {/*<Rating note={parseFloat(this.state.result.environmental_note) / 2} color="#80FF33"  type={"leaf"}></Rating>*/}
                        <Rating note={this.state.result.environmental_note} color="#80FF33"  type={"leaf"}></Rating>
                    </Col>
                    </Row>
                </Col>
            </Row>
        </>
    }

    

    renderCalendar(index)
    {
        if (this.state.calendar_index == index)
        {
            var allDates = this.state.calendar_dates.map(d => { return DateHelper.mysqlDateToJavascript(d)})
            
            var startDate = allDates[0];
            var endDate = allDates[allDates.length - 1];
            var disabledDates = [];
            
            var currentDate = startDate;
            while (currentDate <= endDate)
            {
                var exists = false;
                allDates.forEach(item => {
                    if (DateHelper.toMysqlDateFormat(item) == DateHelper.toMysqlDateFormat(currentDate))
                    {
                        exists = true;
                    }
                })
                // Si la date n'est pas dans le tableau alors on la passe en jour désactivé
                if (exists == false)
                {
                    disabledDates.push(currentDate)
                }

                currentDate = DateHelper.addDaysToDate(1, currentDate)
            }

            return <>
                    <Row>
                    <Col xl="12">
                        <Paragraph>
                            <FormattedMessage id="order.result_detail_step_dates_range_message"></FormattedMessage>
                        </Paragraph>
                    </Col>
                    <Col xl="12" className="text-center">
                        <DateRangePicker 
                            locale={fr} 
                            months={(startDate.getMonth() != endDate.getMonth()) ? 2 : 1}
                            direction="horizontal"
                            showSelectionPreview={false}
                            showMonthAndYearPickers={false}
                            showMonthArrow={false}
                            moveRangeOnFirstSelection={false}
                            showDateDisplay={false}
                            showPreview={false}
                            editableDateInputs={false}
                            dragSelectionEnabled={false}
                            rangeColors={[Resources.icon_primary_color]}
                            onChange={(e) => {}}
                            ranges={[
                                {
                                    startDate: startDate,
                                    endDate: endDate
                                }
                            ]}
                            disabledDates={disabledDates}
                            >
                        </DateRangePicker>
                    </Col>
                    <Col xl="12" className="text-center">
                        <DefaultButton className="t2y-secondary-button small" label={this.translateMessage("order.result_detail_step_dates_range_button")} onClick={(e) => {
                            this.setState({dates: this.state.calendar_dates, openedCollapses: []});
                            this.dates = true;
                            this.props.workflow.reload()
                        }}></DefaultButton>
                    </Col>
                </Row>
            </>
        }
        else
        {
            return <></>
        }
    }

    renderRangeDetail(index, dates)
    {
        this.setState({
            calendar_index: index,
            calendar_dates: dates
        })
        this.dates = false;
        this.props.workflow.reload()
    }

    renderDate(date, halfDay, index)
    {
        const collapseId = "collapse" + index;
        return <>
            <Row>
            {
                (this.state.result.desired_days.length == 1) ?
                (
                    <>
                        <Col xl="12">
                            <div className="custom-control custom-radio mb-3">
                                <input
                                    className="custom-control-input"
                                    id={"dateRadio" + index}
                                    name="customRadio"
                                    type="radio"
                                    onClick={(e) => {
                                        this.setState({dates: [
                                            DateHelper.toMysqlDateFormat(DateHelper.phpDateToJavascript(date.start_date))
                                        ]});
                                        this.dates = true;
                                        this.props.workflow.reload()
                                    }}
                                />
                                <label className="custom-control-label" htmlFor={"dateRadio" + index}>
                                    {/*<DateLabel date={date.start_date} half={halfDay}></DateLabel>*/}
                                    {
                                        halfDay ?
                                        "la demi-journée du " +  DateHelper.dateAsString(date.dates[0][0]) : 
                                        "la journée du " + DateHelper.dateAsString(date.dates[0][0])
                                    }
                                </label>
                            </div>
              
                        </Col>
                    </>
                )
                :
                (   
                    <>
                        <Card className="card-plain" style={{width: "100%"}}>
                            <CardHeader
                            role="tab"
                            onClick={() => this.collapsesToggle(collapseId)}
                            aria-expanded={this.state.openedCollapses.includes(collapseId)}
                            >
                            <h5 className="mb-0">
                                    Départ le <DateLabel date={date.start_date} label={false}></DateLabel>
                                    {/*{date.dates.length} proposition(s) au départ du <DateLabel date={date.start_date} label={false}></DateLabel>*/}
                            </h5>
                            </CardHeader>
                            <Collapse
                            role="tabpanel"
                            isOpen={this.state.openedCollapses.includes(collapseId)}
                            >
                            <CardBody>
                                <Row>
                                    {
                                        date.dates.map((dates, dateIndex) => {            
                                            return <Col xl="12">
                                                <div className="custom-control custom-radio mb-3">
                                                    <input
                                                        className="custom-control-input"
                                                        id={"multi" + index + "-dateRadio" + dateIndex}
                                                        name={"customRadio" + index}
                                                        type="radio"
                                                        onClick={(e) => {
                                                            this.setState({
                                                                dates: dates.map(d => { return DateHelper.toMysqlDateFormat(DateHelper.mysqlDateToJavascript(d))})
                                                            })
                                                            this.dates = true;
                                                            this.props.workflow.reload()
                                                        }}
                                                    />
                                                    <label className="custom-control-label" htmlFor={"multi" + index + "-dateRadio" + dateIndex}>
                                                        {DateHelper.datesAsStringPeriod(dates)}
                                                    </label>
                                                </div>
                                
                                            </Col>
                                        })

                                    }

                                

                                    {/*
                                    <Col xl="4">
                                    {
                                        date.dates.map((dates, dateIndex) => {
                                            return <>
                                                <Row>
                                                    Fin de la mission <DateLabel date={dates[dates.length - 1]} label={false}></DateLabel>
                                                    <DefaultLink className="ml-2" small={true}  onClick={(e) => this.renderRangeDetail(index, dates)}>
                                                        Voir le détail
                                                    </DefaultLink>
                                                </Row>
                                            </>
                                        })
                                    }
                                    </Col>
                                    <Col xl="8" className="t2y-date-range-container">
                                        {
                                            this.renderCalendar(index)
                                        }
                                    </Col>
                                    */}
                                </Row>
                            </CardBody>
                            </Collapse>
                        </Card>
                    </>
                )
            }
            </Row>
        </>
    }

    renderDatesSelection()
    {
        var message = "Cette proposition chiffrée correspond à la mise à disposition du véhicule avec chauffeur pour #{perirdAsString}";
        var component = <></>
        // Date(s) recherché = date(s) proposée(s)
        if (this.state.result.strict_date)
        {
            var dateToStringify = this.state.result.desired_days[0]
            if(typeof dateToStringify === "array") {
                dateToStringify = dateToStringify[0]
            }
            component = <>
                <strong>
                        {
                            message.replace("#{perirdAsString}", this.state.result.half_day ? 
                                "une demi-journée le " +  DateHelper.dateAsString(dateToStringify) :
                                DateHelper.datesAsStringPeriod(this.state.result.desired_days))
                        }
                </strong>
            </>

        } // Date(s) recherché != date(s) proposée(s) mais un seul choix possible
        else if (this.state.result.proposed_days.length == 1)
        {
            component = <>
                <Message type="infos"><FormattedMessage id="order.result_detail_step_one_date_selection_message" /></Message>
                {
                    message.replace("#{perirdAsString}", this.state.result.half_day ? 
                        "une demi-journée le " +  DateHelper.dateAsString(this.state.result.proposed_days[0].dates[0][0]) : 
                        DateHelper.datesAsStringPeriod(this.state.result.proposed_days[0].dates[0]))
                }
            </>

        } // Date(s) recherché != date(s) proposée(s) et plusieurs choix  possibles
        else
        {
            component = <>
                <Message type="infos"><FormattedMessage id="order.result_detail_step_dates_selection_message" /></Message>
                <Col xl="12">
                    <div className="accordion">
                    {
                        this.state.result.proposed_days.map((date, index) => {
                            return <>
                                {this.renderDate(date, this.state.result.half_day, index)}
                            </>
                        })
                    }
                    </div>
                </Col>
            </>
        }

        return <Row>
            <Col xs="12">
                {component}
            </Col>
        </Row>

        /*

        (this.state.result.strict_date) ?
        (
            <Row>
                <Col xs="12">
                    <strong>
                    Cette proposition chiffrée correspond à la mise à disposition du véhicule avec chauffeur pour {
                        this.state.result.half_day ? 
                            "une demi-journée le " +  DateHelper.dateAsString(this.state.result.desired_days[0]) : 
                            DateHelper.datesAsStringPeriod(this.state.result.desired_days)
                    }
                    </strong>
                </Col>
            </Row>
        ) : (this.renderAlternatives())
    }

        if (this.state.result.proposed_days.length == 1)
        {
            return <>
                <Col xl="12">
                    <Title2><FormattedMessage id="order.result_detail_step_dates_selection_title"></FormattedMessage></Title2>
                </Col>
                <Col xl="12">
                    <Message type="infos">
                    <FormattedMessage id="order.result_detail_step_one_date_selection_message" />
                    </Message>
                </Col>
            </>
        }
        else
        {
            return <>
                <Col xl="12">
                <Title2><FormattedMessage id="order.result_detail_step_dates_selection_title"></FormattedMessage></Title2>
                </Col>
                <Col xl="12">
                    <Message type="infos">
                    <FormattedMessage id="order.result_detail_step_dates_selection_message" />
                    </Message>
                </Col>
                <Col xl="12">
                    <div className="accordion">
                    {
                        this.state.result.proposed_days.map((date, index) => {
                            return <>
                                {this.renderDate(date, this.state.result.half_day, index)}
                            </>
                        })
                    }
                    </div>
                </Col>
            </>
        }*/
    }

    renderInformations()
    {
        return <>
            <Row>
                <Col xs="12">
                    <Title3><FormattedMessage id="order.result_detail_step_information_title" /></Title3>
                    <Paragraph>
                        <FormattedMessage id="order.result_detail_step_information_message" />
                    </Paragraph>
            
                </Col>
                <Col xs="6">
                    <DefaultLabel>
                        <FormattedMessage id={this.state.result.half_day ? "order.result_detail_step_half_day_title" : "order.result_detail_step_full_day_title"}></FormattedMessage>
                    </DefaultLabel>
                </Col>
                <Col xs="6">
                    <label>{this.state.result.half_day ? this.state.result.bnd_distance_per_half_day : this.state.result.bnd_distance_per_day} <FormattedMessage id="order.result_detail_step_km_per_day_label" /></label>
                    <br/>
                    <label>{this.state.result.half_day ? this.state.result.bnd_hours_per_half_day : this.state.result.bnd_hours_per_day} <FormattedMessage id="order.result_detail_step_hours_per_day_label" /></label>
                </Col>
                <Col xs="6">
                    <DefaultLabel>Km supplémentaire</DefaultLabel>
                </Col>
                <Col xs="6">
                    <label>{this.state.result.cost_km_as_string} €</label>
                </Col>
                <Col xs="6">
                    <DefaultLabel>Heure supplémentaire</DefaultLabel>
                </Col>
                <Col xs="6">
                    <label>{this.state.result.cost_hour_as_string} €</label>
                </Col>
                <Col xs="6">
                    <DefaultLabel>Distance d'approche</DefaultLabel>
                </Col>
                <Col xs="6">
                    <label>{this.state.result.distance} Km</label>
                </Col>
            </Row>
        </>
    }

    render() {
        if (this.state.transporter)
        {
            return <>

            </>
        }

        return <>
            {this.renderParent()}
            <FormLoadingIndicator loading={this.state.loading}></FormLoadingIndicator>
            {
                //(!this.busy()) ?
                (
                    (this.state.result != null) ?
                    (
                        <>
                            {
                                (this.state.result.automatic_validation == false) ?
                                (
                                    <Row>
                                        <Col xs="12">
                                            <Message type="infos">
                                                <FormattedMessage id="order.result_detail_step_automatic_validation_false">
                                                    {this.decodeEntities}
                                                </FormattedMessage>
                                            </Message>
                                        </Col>
                                    </Row>
                                ) : (<></>)
                            }
                            <Row>
                                
                                <Col md="6">
                                    {this.renderTruckDetail()}
                                </Col>
                                <Col md="6">
                                    <Row>
                                        <Col md="5">
                                            <Title3>
                                                <FormattedMessage id="order.result_detail_step_proposition_title" />
                                            </Title3>
                                        </Col>
                                        <Col md="7">
                                            <Title2 style={{textAlign:"right"}}>
                                            {
                                                StringHelper.formatAmountWithPattern(
                                                    (this.state.result.amount_to_pay_ht ? this.state.result.amount_to_pay_ht : ""), 
                                                    "€",
                                                    "{amount} {devise} HT"
                                                )
                                            }
                                            </Title2>
                                        </Col>
                                    </Row>
                                    { this.renderDatesSelection() }

                                    
                                    <hr/>
                                    {this.renderInformations()}

                                    {
                                        (this.order_id != null) ?
                                        (
                                            <>
                                            Vous commande a été créée pour : <DateLabel start_date={this.state.result.order_start_date} end_date={this.state.result.order_end_date} />
                                            </>
                                        ) : <></>
                                        
                                    }
                                    <Row className="justify-content-center">
                                        {
                                            (this.order_payment_transaction_date != null) ?
                                            (
                                                <DefaultButton style={{float:"right"}} icon={Resources.orders_logo} className="t2y-secondary-button small" onClick={() => WebsiteHelper.goTo(this, this.order_url)}>Voir ma commande</DefaultButton>
                                            ) : (<></>)
                                        }


                                        {
                                            (!this.props.disableOrder && !this.isReadOnlyResult()) ?
                                            (
                                                this.props.workflow.renderNexts(this.props.workflow.getCurrentStep())
                                            ) : (null)
                                        }
                                    </Row>

                                </Col>
                            </Row>

                        </>
                    ) : (<></>)

                )
                /*:
                (
                    <>
                    {this.renderAsBusy()}
                    </>
                )*/
            }
        </>
    }
}


export default injectIntl(ResultDetailStep)
